/* Initial CSS */

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  /* display: flex; */
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS */

body {
  overflow: hidden;
}

canvas {
  display: block;
}

#mousePointer {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  font-size: 38px;
  z-index: 1;
}

#joystick-rotate {
  width: 100%;
  height: 100%;
  position: absolute !important;
  z-index: 2;
}

#joystick-move {
  width: 200px;
  height: 200px;
  position: absolute !important;
  bottom: 0;
  left: 0;
  z-index: 3;
}

#buttons-blockedit {
  position: absolute;
  bottom: 0;
  right: 90px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  z-index: 4;
}

#buttons-blockedit-mode {
  position: absolute;
  width: 85px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto 20px;
  z-index: 4;
}

#buttons-move {
  margin-top: 16px;
}

#buttons-mode {
  position: absolute;
  top: 45%;
  right: 0;
  margin-right: 20px;
  z-index: 4;
}

#color-palette {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

#instructions {
  width: 100%;
  height: 100%;
  position: absolute;
  letter-spacing: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  text-align: center;
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  z-index: 10;
}

#instructions .container {
  max-width: 100%;
  height: 100%;
  padding: 0 25px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

#instructions.mobile .container {
  display: block;
  overflow: scroll;
}

#instructions .content {
  width: 100%;
  padding: 35px 0;
}

.animate {
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
}

.btn-container {
  height: 70px;
  margin-bottom: 20px;
}

.btn {
  padding: 7px 50px;
  border-radius: 3px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  letter-spacing: 10px;
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 40px;
  cursor: pointer;
  outline: none;
}

.btn.green {
  opacity: 1;
  color: rgb(255, 255, 255);
  background-color: rgb(122, 122, 121);
  border-bottom: 6px solid#61dafb;
  text-shadow: 2px 2px #61dafb;
}

.btn2 {
  padding: 7px 14px;
  border-radius: 3px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  margin: 4px 10px;
  letter-spacing: 2px;
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 22px;
  cursor: pointer;
  outline: none;
}

.btn2.set {
  color: #fff;
  background-color: #718088;
  border-bottom: 2px solid #4d4d4d;
  text-shadow: 0px -1px #5f5f5f;
}

.btn:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  border-bottom: 0;
}

.btn:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  border-bottom: 0;
}

.btn2:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  border-bottom: 0;
}

.btn2:active {
  transform: translate(0px, 5px);
  -webkit-transform: translate(0px, 5px);
  border-bottom: 0;
}


.btn:disabled {
  opacity: 0.5;
}

.cbx {
  top: 2px;
  position: relative;
}

.logo {
  max-width: 640px;
  padding: 5px 0 20px;
  margin: auto;
}

.logo.mobile {
  width: 80%;
  margin: 0 auto;
}

.howto {
  max-width: 960px;
  padding: 20px;
  margin: 0 auto 40px;
}

.welcome-message {
  max-width: 640px;
  margin: auto;
}


.no-select {
  user-select: none;
}

.hide {
  display: none !important;
}


.icon-btn {
  width: 40px;
  height: 40px;
  padding: 0;
  background: none;
  border: none;
}

.icon-btn img {
  width: 100%;
}


.colorpicker {
  display: none;
}

.block-btn {
  position: relative;
  width: 62px;
  height: 62px;
  padding: 0;
  background: none;
  border: none;
}

.block-btn img {
  position: relative;
  width: 100%;
  z-index: 5;
}

.block-btn .block-color {
  position: absolute;
  width: 29px;
  height: 29px;
  top: 17px;
  right: 17px;
  z-index: 4;
}


.blockedit-mode-btn {
  padding: 0;
  background: none;
  border: none;
}

.blockedit-mode-btn img {
  display: block;
  width: 100%;
}


.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}


.move-btn {
  width: 40px;
  height: 40px;
  margin-top: 8px;
}

#move-up-btn {
  width: 100%;
}

#move-down-btn {
  width: 100%;
}
